import React from 'react'

import Config from './Config'
import { useGenesysImporter } from './useGenesysImporter'

const Genesys: React.FC = () => {
  const { data = [] } = useGenesysImporter()

  return data.map((d) => <Config key={d.uid} {...d} />)
}

export default Genesys
