import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import WebhookLogTable from '../WebhookLogTable'

type Props = {} & BaseModalComponentProps

const WebhookLogModal: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <Modal size="6xl" isOpen={isOpen ?? false} onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <Flex direction="column">
            <ModalHeader borderBottom="1px solid" borderColor="gray.200">
              <Trans>Webhook log</Trans>
            </ModalHeader>
          </Flex>
          <ModalCloseButton size="lg" />
          <ModalBody maxH="700px" overflowY="auto">
            <WebhookLogTable />
          </ModalBody>
          <ModalFooter>
            <HStack spacing={4} justify="flex-end">
              <Button onClick={onClose}>
                <Trans>Close</Trans>
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default WebhookLogModal
