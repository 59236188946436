import { createPatchPayload } from '@capturi/api-shared'
import request, { ResponseError } from '@capturi/request'
import {
  InfiniteData,
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import {
  UpdateWebhookFormModel,
  Webhook,
  WebhookEvent,
  WebhookPayload,
} from './models/Webhooks'

type ReplayRequestPayload = {
  period: { from: Date; to: Date }
  event: string
}

const cacheKey = 'webhooks'
const compare = Intl.Collator().compare

const selectedWebhooks = (data: Webhook[]): Webhook[] => {
  return data?.sort((a, b) => compare(a.title, b.title))
}

export const useWebHook = (uid: string): UseQueryResult<Webhook> =>
  useQuery({
    queryKey: [cacheKey, uid],
    queryFn: () => {
      const webhook = request.get<Webhook>(
        `webhooks/${uid}/unredacted?api-version=3.3`,
      )
      return webhook
    },
  })

export const useWebHooks = (): UseQueryResult<Webhook[], ResponseError> =>
  useQuery({
    queryKey: [cacheKey],
    queryFn: async () => {
      const result = await request.get<{ webhooks: Webhook[] }>(
        'webhooks?api-version=3.3',
      )
      return result.webhooks
    },
    select: selectedWebhooks,
  })

export const useCreateWebhook = (): UseMutationResult<
  Webhook,
  ResponseError,
  WebhookPayload,
  ResponseError
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (model: WebhookPayload) => {
      return request.post<Webhook>('webhooks?api-version=3.3', {
        json: model,
      })
    },
    onSuccess: (newWebhook) => {
      queryClient.setQueryData<Webhook[]>([cacheKey], (oldData) => {
        if (!oldData) {
          return [newWebhook]
        }
        return [newWebhook, ...oldData]
      })
    },
  })
}

export const useUpdateWebhook = (): UseMutationResult<
  Webhook,
  ResponseError,
  UpdateWebhookFormModel,
  ResponseError
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ uid, webhook }: UpdateWebhookFormModel) => {
      const oldWebhook = queryClient.getQueryData<Webhook>([cacheKey, uid])
      const payload = createPatchPayload(oldWebhook, webhook)
      return request.patch<Webhook>(`webhooks/${uid}?api-version=3.3`, {
        json: payload,
      })
    },
    onSuccess: (newWebhook) => {
      queryClient.setQueryData<Webhook[]>([cacheKey], (oldData) => {
        if (!oldData) {
          return [newWebhook]
        }
        return oldData.map((f) => (f.uid === newWebhook.uid ? newWebhook : f))
      })
      queryClient.setQueryData<Webhook>([cacheKey, newWebhook.uid], () => {
        return newWebhook
      })
    },
  })
}

export const useDeleteWebhook = (): UseMutationResult<
  Webhook,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (uid: string) => {
      return request.delete<Webhook>(`webhooks/${uid}?api-version=3.3`)
    },
    onSuccess: (_, webhookUid) => {
      queryClient.setQueryData<Webhook[]>([cacheKey], (oldData) => {
        if (!oldData) {
          return []
        }
        return oldData.filter((wh) => wh.uid !== webhookUid)
      })
    },
  })
}

export const useWebHookEvents = (): UseInfiniteQueryResult<
  InfiniteData<WebhookEvent[]>,
  ResponseError
> =>
  useInfiniteQuery<WebhookEvent[], ResponseError>({
    queryKey: ['webhookEvents'],
    queryFn: async ({ pageParam }) => {
      const result = await request.get<{ events: WebhookEvent[] }>(
        `webhooks/events?pageNumber=${
          pageParam || 0
        }&pageSize=10&sortDirection=Descending&api-version=3.3`,
      )
      return result.events
    },
    getNextPageParam: (currentPage, allPages) => {
      if (currentPage.length < 10) return undefined
      return allPages.length
    },
    initialPageParam: null,
  })

export const useReplayWebhookEvent = (): UseMutationResult<
  WebhookEvent,
  ResponseError,
  string,
  ResponseError
> => {
  return useMutation({
    mutationKey: ['webhookEvents'],
    mutationFn: (uid: string) =>
      request.post<WebhookEvent>(
        `webhooks/events/${uid}/replay?api-version=3.3`,
      ),
  })
}

export const useReplayWebhookEvents = (): UseMutationResult<
  WebhookEvent,
  ResponseError,
  ReplayRequestPayload,
  ResponseError
> => {
  return useMutation({
    mutationKey: ['webhookEvents'],
    mutationFn: ({ period, event }: ReplayRequestPayload) =>
      request.post<WebhookEvent>('webhooks/events/replay?api-version=3.3', {
        json: {
          from: period.from,
          to: period.to,
          event: event,
        },
      }),
  })
}
