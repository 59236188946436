import { DateRangePicker } from '@capturi/ui-components'
import {
  Box,
  Button,
  Divider,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { Trans } from '@lingui/macro'
import { endOfDay, startOfDay, subMonths } from 'date-fns'
import {
  TableWrapper,
  TableWrapperHeader,
} from 'pages/Organization/components/TableWrapper'
import React, { useState } from 'react'
import { MdUpdate } from 'react-icons/md'
import {
  useReplayWebhookEvents,
  useWebHookEvents,
} from '../Api/Webhooks/useWebhooks'
import WebhookLogTableRow from './Rows/WebhookLogTableRow'

const WebhookLogTable: React.FC = () => {
  const [toggleDatepicker, setToggleDatepicker] = useState<boolean>(false)
  const toast = useToast()
  const [date, setDate] = useState({
    from: startOfDay(subMonths(new Date(), 1)),
    to: endOfDay(new Date()),
  })

  const { mutate: replayEvents, isPending: isReplayingLoading } =
    useReplayWebhookEvents()
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useWebHookEvents()

  const handleNextPage = (): void => {
    fetchNextPage()
  }

  const refreshAllEvents = (): void => {
    replayEvents(
      { period: date, event: 'ConversationSummaryUpdated' },
      {
        onSuccess: () => {
          toast({
            title: t`Successfully replayed events`,
            status: 'success',
          })
        },
        onError: (error) => {
          toast({
            title: t`Error replaying events. Try again.`,
            status: 'error',
            description: error.message,
          })
        },
      },
    )
  }

  function selectRange(range: { from: Date; to: Date }): void {
    setDate(range)
  }
  const isEmpty = !!data?.pages[0].length
  if (isLoading) {
    return <Spinner />
  }
  return (
    <Box>
      <Flex mb={4} mt={2} justifyContent="end">
        <Popover placement="bottom-end">
          {({ onClose }) => (
            <>
              <PopoverTrigger>
                <Button
                  aria-label={t`Button to replay webhook events`}
                  isLoading={isReplayingLoading}
                  leftIcon={<MdUpdate />}
                  textAlign="right"
                  onClick={() => setToggleDatepicker(!toggleDatepicker)}
                >
                  <Text float="left">
                    <Trans>Replay events in a date interval</Trans>
                  </Text>
                </Button>
              </PopoverTrigger>
              <PopoverContent width="100%">
                <span>
                  <PopoverArrow />
                </span>
                <PopoverBody px={0} pt={4}>
                  <Flex flexDir="column" alignItems="flex-start" gap={2}>
                    <Box>
                      <DateRangePicker
                        value={date}
                        showInputs
                        placeholderFrom={t`From`}
                        placeholderTo={t`To`}
                        onSelectDateRange={selectRange}
                      />
                    </Box>
                    <Divider />
                    <Flex justifyContent="flex-end" width="100%" px={4} gap={2}>
                      <Button onClick={onClose}>
                        <Trans>Cancel</Trans>
                      </Button>
                      <Button
                        colorScheme="primary"
                        onClick={() => {
                          onClose()
                          refreshAllEvents()
                        }}
                      >
                        <Trans>Replay events</Trans>
                      </Button>
                    </Flex>
                  </Flex>
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
      </Flex>
      <Box width="full" overflowX="auto" mb={4}>
        <TableWrapper>
          <TableWrapperHeader
            title={t`Webhook event log`}
            tooltip={t`Log displaying the list of all previous webhook events`}
          />
          <Table variant="bordered">
            <Thead>
              <Tr>
                <Th>
                  <Trans>Call URL</Trans>
                </Th>
                <Th>
                  <Trans>Last request</Trans>
                </Th>
                <Th>
                  <Trans>Last outcome</Trans>
                </Th>
                <Th>
                  <Trans>Response code</Trans>
                </Th>
                <Th w="5%" />
              </Tr>
            </Thead>
            <Tbody>
              {!isEmpty ? (
                <Tr w="100%">
                  <Td w="100%">
                    <Text fontSize="md" fontWeight="bold" textAlign="center">
                      <Trans>No content. Start by creating a webhook</Trans>
                    </Text>
                  </Td>
                </Tr>
              ) : (
                <>
                  {(data.pages ?? [])?.map((events) =>
                    events.map((e) => {
                      return <WebhookLogTableRow key={e.uid} {...e} />
                    }),
                  )}
                </>
              )}
            </Tbody>
          </Table>
        </TableWrapper>
      </Box>
      <Flex justifyContent="center" mt={4}>
        <Button
          onClick={handleNextPage}
          isDisabled={!hasNextPage || isFetchingNextPage}
          isLoading={isFetchingNextPage}
        >
          {!hasNextPage ? (
            <Trans>No more events</Trans>
          ) : (
            <Text>
              <Trans>Load more</Trans>
            </Text>
          )}
        </Button>
      </Flex>
    </Box>
  )
}

export default WebhookLogTable
