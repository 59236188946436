import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import {
  useRepeatCallsBlacklist,
  useSystemSettings,
  useUpdateRepeatCallsBlacklist,
  useUpdateSystemSettings,
} from 'pages/Organization/hooks/useUpdateSystemSettings'
import { SystemSettings } from 'pages/Organization/types'
import React, { useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'
import BlacklistPhoneNumbers from './Components/BlacklistPhoneNumbers'
import DefaultScenario from './Components/DefaultScenario'
import FollowUp from './Components/FollowUp'

const selectRepeatCallPolicy = (data: SystemSettings) => {
  return data.repeatCall
}

const RepeatCalls: React.FC = () => {
  const { mutate: updateSystemSettings } = useUpdateSystemSettings()
  const { data: repeatCalls } = useSystemSettings({
    select: selectRepeatCallPolicy,
  })
  const { data: repeatCallsBlacklist } = useRepeatCallsBlacklist()
  const { mutate: updateRepeatCallBlacklist } = useUpdateRepeatCallsBlacklist()
  const toast = useToast()

  const [phoneNumbers, setPhoneNumbers] = useState<string[]>(
    repeatCallsBlacklist?.customers ?? [],
  )
  useEffect(() => {
    if (repeatCallsBlacklist) {
      setPhoneNumbers(repeatCallsBlacklist.customers)
    }
  }, [repeatCallsBlacklist])

  const [state, setState] = useState<SystemSettings['repeatCall']>({
    //Convertion since API handles the days in seconds.
    maxDistance: repeatCalls?.maxDistance ? repeatCalls.maxDistance / 86400 : 1,
    //This value is strictly set to 15 minutes (in seconds for api) OR null (0) if the value is set to "0"
    minDistance:
      repeatCalls?.minDistance === null
        ? 0
        : repeatCalls?.minDistance
          ? repeatCalls.minDistance / 60
          : 900 / 60,
  })

  const handleOk = (): void => {
    const areRepeatCallValuesEqual = isEqual(
      {
        maxDistance: state?.maxDistance ? state?.maxDistance * 86400 : null,
        minDistance: state?.minDistance ? state?.minDistance * 60 : null,
      },
      {
        maxDistance: repeatCalls?.maxDistance,
        minDistance: repeatCalls?.minDistance,
      },
    )
    if (!areRepeatCallValuesEqual) {
      updateSystemSettings(
        {
          repeatCall: {
            maxDistance: state.maxDistance ? state.maxDistance * 86400 : null,
            minDistance: state.minDistance ? state.minDistance * 60 : null,
          },
        },
        {
          onSuccess: () => {
            toast({
              status: 'success',
              title: t`Successfully updated repeat call policy`,
            })
          },
          onError: (error) => {
            toast({
              status: 'error',
              title: t`Failed to update repeat call policy`,
              description: error.message,
            })
          },
        },
      )
    }
    const areBlacklistsEqual = isEqual(
      repeatCallsBlacklist?.customers,
      phoneNumbers,
    )
    if (!areBlacklistsEqual) {
      updateRepeatCallBlacklist(phoneNumbers, {
        onSuccess: () => {
          toast({
            status: 'success',
            title: t`Successfully updated repeat call blacklist`,
          })
        },
        onError: (error) => {
          toast({
            status: 'error',
            title: t`Failed to update repeat call blacklist`,
            description: error.message,
          })
        },
      })
    }
  }

  const onScenarioChange = (value: number) => {
    setState({ ...state, maxDistance: value })
  }

  const onChange = (value: number) => {
    setState({ ...state, minDistance: value })
  }

  const handleCancel = () => {
    setState({
      maxDistance: repeatCalls?.maxDistance
        ? repeatCalls.maxDistance / 86400
        : 1,
      minDistance:
        repeatCalls?.minDistance === null
          ? 0
          : repeatCalls?.minDistance
            ? repeatCalls.minDistance / 60
            : 900 / 60,
    })
  }

  return (
    <Flex flexDir="column">
      <HStack gap={8} align="start">
        <VStack pb={4} w="50%" align="flex-start">
          <Box mb={4}>
            <DefaultScenario
              onChange={onScenarioChange}
              value={state.maxDistance}
            />
          </Box>
          <Box mb={4}>
            <FollowUp onChange={onChange} value={state.minDistance} />
          </Box>
        </VStack>
        <Divider orientation="vertical" height="50px" borderColor="gray.200" />
        <VStack w="50%" align="flex-start">
          <BlacklistPhoneNumbers
            phoneNumbers={phoneNumbers}
            setPhoneNumbers={setPhoneNumbers}
          />
        </VStack>
      </HStack>
      <HStack justify="flex-end" w="100%">
        <Button onClick={handleCancel}>
          <Trans>Cancel</Trans>
        </Button>
        <Button colorScheme="primary" onClick={handleOk}>
          <Trans>Save repeat call policy</Trans>
        </Button>
      </HStack>
    </Flex>
  )
}

export default RepeatCalls
