import {
  Flex,
  FormControl,
  HStack,
  Icon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { MdTimer } from 'react-icons/md'

type Props = {
  onChange: (value: number) => void
  value: number | null
}

const DefaultScenario: React.FC<Props> = ({ onChange, value }) => {
  return (
    <VStack align="flex-start" gap={0}>
      <Flex align="center">
        <Icon as={MdTimer} mr={2} />
        <Text fontWeight="medium" fontSize="md">
          <Trans>Default repeat call scenario</Trans>
        </Text>
      </Flex>
      <Text color="gray.600" fontSize="sm">
        <Trans>
          Used as the standard setting for repeat call filter and analysis.
        </Trans>
      </Text>
      <Flex>
        <FormControl mt={2}>
          <HStack>
            <Text fontSize="sm">
              <Trans>At least ONE follow-up call within</Trans>
            </Text>
            <NumberInput
              min={1}
              max={100}
              size="xs"
              onChange={(_, valueAsNumber) => {
                onChange(valueAsNumber)
              }}
              value={value ?? '0'}
            >
              <NumberInputField h="24px" w="54px" p={2} />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Text fontSize="sm">
              <Trans>days</Trans>
            </Text>
          </HStack>
        </FormControl>
      </Flex>
    </VStack>
  )
}

export default DefaultScenario
