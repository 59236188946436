export const regions = [
  'mypurecloud.com',
  'mypurecloud.ie',
  'mypurecloud.com.au',
  'mypurecloud.jp',
  'mypurecloud.de',
  'usw2.pure.cloud',
  'cac1.pure.cloud',
  'apne2.pure.cloud',
  'euw2.pure.cloud',
  'aps1.pure.cloud',
  'use2.us-gov-pure.cloud',
  'sae1.pure.cloud',
] as const

export const URL_KEY = 'genesys-chat'

export type GenesysChatFilters = Record<string, never>
export type GenesysChatAuth = {
  clientId: string
  clientSecret: string
  region: (typeof regions)[number]
}

export type GenesysChatResponse = {
  auth: GenesysChatAuth
  filters: GenesysChatFilters
  lastRunStarted: Date
  lastRunEnded: Date
  continuationToken: Date
  customFields: string[]
  friendlyName: string
  uid: string
  organizationUid: string
  isActive: boolean
}

export type GenesysChatAddPayload = {
  auth: GenesysChatAuth
  friendlyName: string
  continuationToken: Date
}
export type GenesysChatPatchPayload = Partial<{
  auth: Partial<{
    token: string
  }>
  friendlyName: string
  continuationToken: Date
  customFields: string[]
  filters: Partial<GenesysChatFilters>
}>
