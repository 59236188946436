import { useInput } from '@capturi/react-utils'
import { useUsers } from '@capturi/stores'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  Tooltip,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import Link from 'components/Link'
import React, { useState } from 'react'
import { MdAdd } from 'react-icons/md'
import {
  Webhook,
  WebhookHeader,
  WebhookPayload,
} from '../../Api/Webhooks/models/Webhooks'
import {
  useCreateWebhook,
  useUpdateWebhook,
} from '../../Api/Webhooks/useWebhooks'
import HttpHeader from '../HttpHeader'

type Props = { webhook?: Webhook } & BaseModalComponentProps

const date: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

const WebhookModal: React.FC<Props> = ({ onClose, isOpen, webhook }) => {
  const { getUserByUid } = useUsers()
  const [state, setState] = useInput<WebhookPayload>({
    title: webhook?.title ?? '',
    description: webhook?.description ?? '',
    event: webhook?.event ?? 'ConversationSummaryUpdated',
    headers: webhook?.headers ?? {},
    method: webhook?.method ?? 'POST',
    url: webhook?.url ?? '',
    payload: webhook?.payload ?? 'Uid',
    enabled: webhook?.enabled ?? true,
  })

  const initialValue = React.useMemo(() => {
    return Object.entries(webhook?.headers ?? []).map((i) => ({
      key: i[0],
      value: i[1],
    }))
  }, [webhook?.headers])
  const [headers, setHeaders] = useState<WebhookHeader[]>(initialValue ?? [])

  const toast = useToast()

  const isSubmitDisabled =
    !state.url ||
    (!!headers.length &&
      headers.some((h) => {
        if (h.key === '' || h.value === '') return true
        return false
      }))

  const { mutate: createWebhook, isPending: isCreateLoading } =
    useCreateWebhook()
  const { mutate: updateWebhook, isPending: isUpdateLoading } =
    useUpdateWebhook()

  const isLoading = isCreateLoading || isUpdateLoading

  const handleSubmit = (model: WebhookPayload) => {
    const headersObj = headers.reduce<Record<string, string>>(
      (memo, header) => {
        memo[header.key] = header.value
        return memo
      },
      {},
    )
    const tmpModel = { ...model, headers: headersObj }
    if (webhook?.uid) {
      updateWebhook(
        { webhook: tmpModel, uid: webhook.uid },
        {
          onSuccess: () => {
            toast({
              status: 'success',
              title: t`Successfully updated webhook "${tmpModel.title}"`,
            })
            onClose()
          },
          onError: (error) => {
            toast({
              status: 'error',
              title: t`Could not create webhook. Try again.`,
              description: error.message,
            })
          },
        },
      )
    } else {
      createWebhook(tmpModel, {
        onSuccess: () => {
          toast({
            status: 'success',
            title: t`Successfully created webhook "${tmpModel.title}"`,
          })
          onClose()
        },
        onError: (error) => {
          toast({
            status: 'error',
            title: t`Could not create webhook. Try again.`,
            description: error.message,
          })
        },
      })
    }
  }

  const addHeader = (): void => {
    setHeaders((prevState) => [...prevState, { key: '', value: '' }])
  }

  const removeHeader = (index: number): void => {
    const tmpHeaders = [...headers]
    tmpHeaders.splice(index, 1)
    setHeaders(tmpHeaders)
  }

  const handleUpdateHeader = (index: number, key?: string, value?: string) => {
    const updatedHeader = { ...headers[index] }
    if (key !== undefined) {
      updatedHeader.key = key
    } else if (value !== undefined) {
      updatedHeader.value = value
    }
    const newHeaders = headers.slice()
    newHeaders[index] = updatedHeader
    setHeaders(newHeaders)
  }

  return (
    <Modal isOpen={isOpen ?? false} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create webhook</ModalHeader>
        <ModalCloseButton isDisabled={isLoading} />
        <ModalBody pb={6}>
          <FormControl mt={4}>
            <FormLabel mb={0}>Name</FormLabel>
            <Input {...setState.title} placeholder="Name" />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel mb={0}>Description</FormLabel>
            <Input {...setState.description} placeholder="Description" />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel mb={0}>Event</FormLabel>
            <Select id="webhook-event" {...setState.event}>
              <option value="ConversationSummaryUpdated">
                ConversationSummaryUpdated
              </option>
              <option value="ConversationProcessed">
                ConversationProcessed
              </option>
            </Select>
          </FormControl>
          <Text color="gray.600" fontSize="sm">
            <Trans>
              Webhooks only currently supports being envoked when a given
              summary on a conversation is created.
            </Trans>
          </Text>
          <FormControl mt={4}>
            <FormLabel mb={0}>Call URL</FormLabel>
            <Input
              {...setState.url}
              placeholder="http://foo.bar?api-token=XXX"
            />
          </FormControl>
          <RadioGroup mt={4} id="webhookRequestMethod" value={state.method}>
            <FormLabel>Method</FormLabel>
            <Stack direction="row">
              <Radio {...setState.method} value="POST">
                POST
              </Radio>
              <Radio {...setState.method} value="PUT">
                PUT
              </Radio>
              <Radio {...setState.method} value="PATCH">
                PATCH
              </Radio>
            </Stack>
          </RadioGroup>
          <FormControl mt={4}>
            <FormLabel>Optional headers</FormLabel>
            <VStack gap={0}>
              <HStack w="100%">
                <Flex flex="50%">
                  <Text fontWeight="bold" fontSize="xs">
                    <Trans>Key</Trans>
                  </Text>
                </Flex>
                <Flex flex="50%">
                  <Text fontWeight="bold" fontSize="xs">
                    <Trans>Value</Trans>
                  </Text>
                </Flex>
              </HStack>
              <HttpHeader
                headers={headers}
                onHeaderPropChange={handleUpdateHeader}
                onRemoveHeaderChange={removeHeader}
              />
            </VStack>
            <Button
              p={0}
              _hover={{ backgroundColor: 'none' }}
              _focus={{ backgroundColor: 'none' }}
              color="primary.500"
              variant="ghost"
              fontSize="sm"
              onClick={addHeader}
              leftIcon={<MdAdd />}
            >
              <Trans>Add key/value pair</Trans>
            </Button>
          </FormControl>
          <RadioGroup mt={4} id="WebhookPayloadType" value={state.payload}>
            <FormLabel>Payload</FormLabel>
            <Stack direction="row">
              <Radio {...setState.payload} value="Full">
                Full
              </Radio>
              <Radio {...setState.payload} value="Uid">
                UID
              </Radio>
            </Stack>

            <Text>
              <Trans>
                The full payload model is the same as
                <Text fontFamily="monospace">
                  GET integrations/conversations/:uid
                </Text>
                and can be seen{' '}
                <Link
                  textDecoration="underline"
                  color="primary.500"
                  to="https://api.capturi.ai/audio/swagger/docs/index.html?version=v2#tag/IntegrationConversations/paths/~1integrations~1conversations~1%7Buid%7D/get"
                >
                  here.
                </Link>
              </Trans>
            </Text>
          </RadioGroup>
          <Box mt={4}>
            <FormLabel>
              <Trans>Enable/Disable webhook</Trans>
            </FormLabel>
            <Checkbox
              isChecked={state.enabled}
              onChange={setState.enabled.onChange}
            />
            <Text color="gray.600" fontSize="sm">
              <Trans>
                Disabling this will disable the webhook, stopping it from
                triggering.
              </Trans>
            </Text>
          </Box>
          {webhook && (
            <Box mt={4}>
              <Text color="gray.600" fontSize="xs">
                <Trans>Created by </Trans>
                {getUserByUid(webhook?.createdByUserUid).name}{' '}
                {i18n.date(webhook?.created, date)}
              </Text>
              <Text color="gray.600" fontSize="xs">
                <Trans>Updated by </Trans>
                {getUserByUid(webhook?.updatedByUserUid).name}{' '}
                {i18n.date(webhook?.updated, date)}
              </Text>
            </Box>
          )}
        </ModalBody>

        <ModalFooter>
          <Button mr={3} isLoading={isLoading} onClick={onClose}>
            Cancel
          </Button>
          <Tooltip
            isDisabled={!isSubmitDisabled}
            hasArrow
            placement="top"
            label={
              !state.url
                ? t`Call URL cannot be empty.`
                : t`Headers can not be empty. Either fill them out or remove them`
            }
          >
            <Button
              isDisabled={isSubmitDisabled}
              isLoading={isLoading}
              colorScheme="primary"
              onClick={() => handleSubmit(state)}
            >
              {webhook?.uid ? t`Save webhook` : t`Create webhook`}
            </Button>
          </Tooltip>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default WebhookModal
