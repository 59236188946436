import { useModal } from '@capturi/use-modal'
import { HStack, IconButton, Td, Tr, useToast } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'
import { MdCopyAll, MdDelete, MdEdit } from 'react-icons/md'
import { useCopyToClipboard } from 'react-use'
import { Webhook } from '../../Api/Webhooks/models/Webhooks'
import { useDeleteWebhook, useWebHook } from '../../Api/Webhooks/useWebhooks'
import WebhookModal from '../Modals/WebhookModal'

const WebhookTableRow: React.FC<Webhook> = ({ ...props }) => {
  const [openWebhookModal] = useModal(WebhookModal)
  const { mutate: deleteWebhook } = useDeleteWebhook()
  const { data: webhook } = useWebHook(props.uid)
  const [, copy] = useCopyToClipboard()
  const toast = useToast()

  const handleDeleteWebhook = (): void => {
    deleteWebhook(props.uid, {
      onSuccess: () => {
        toast({ status: 'success', title: t`Webhook has been deleted.` })
      },
      onError: () => {
        toast({
          status: 'error',
          title: t`Could not delete webhook. Try again.`,
        })
      },
    })
  }
  return (
    <Tr>
      <Td>{props.title}</Td>
      <Td>{props.event}</Td>
      <Td>{props.method}</Td>
      <Td>
        <HStack>
          <IconButton
            size="xs"
            variant="ghost"
            icon={<MdEdit />}
            aria-label={t`Copy webhook to clipboard`}
            onClick={() => {
              openWebhookModal({ webhook: webhook })
            }}
          />
          <IconButton
            size="xs"
            variant="ghost"
            icon={<MdDelete />}
            aria-label={t`Copy webhook to clipboard`}
            onClick={handleDeleteWebhook}
          />
          <IconButton
            size="xs"
            variant="ghost"
            icon={<MdCopyAll />}
            aria-label={t`Copy webhook to clipboard`}
            onClick={() => {
              copy(props.url)
              toast({
                status: 'success',
                title: t`Copied webhook url to clipboard.`,
              })
            }}
          />
        </HStack>
      </Td>
    </Tr>
  )
}

export default WebhookTableRow
